import React from 'react';

const Contact = () => {
    return (
        <>
            <div className="container-xxl contact py-lg-5 py-2">
                <div className="container">
                    <div
                        className="section-title text-center mx-auto wow fadeInUp"
                        data-wow-delay="0.1s"
                        style={{ maxWidth: 500 }}
                    >
                        <p className="fs-5 fw-medium fst-italic text-primary">যোগাযোগ</p>
                        <h3 className="">আমাদের সাথে যোগযোগের মাধ্যম</h3>
                    </div>
                    <div
                        className="row justify-content-center wow fadeInUp"
                        data-wow-delay="0.1s"
                    >
                        <div className="col-lg-8">
                            <p className="text-center mb-3">
                               আপনি চাইলে নিচের মেইল, মোবাইল অথবা হোয়াটসঅ্যাপ এর মাধ্যমে যোগাযোগ করতে পারেন। তাছাড়া আপনি চাইলে আমাদের অফিসে সরাসরি এসে ভিজিট করতে পারেন। আপনাকে আমাদের অফিসে আসার আমন্ত্রণ রইল। এছাড়া ফেসবুক পেজের ম্যাসেঞ্জারের মাধ্যমে আপনি আমাদের সাথে যোগাযোগ করতে পারেন। আমাদের একজন প্রতিনিধি সার্বক্ষণিক আপনাদের সেবায় নিয়োজিত থাকে।
                            </p>
                            <div className="row g-3">
                                <div
                                    className="col-md-4 text-center wow fadeInUp"
                                    data-wow-delay="0.3s"
                                >
                                    <div className="btn-square mx-auto mb-3">
                                        <i className="fa fa-envelope fa-2x text-white" />
                                    </div>
                                    <p className="mb-2">m3medicarebd@gmail.com</p>
                                </div>
                                <div
                                    className="col-md-4 text-center wow fadeInUp"
                                    data-wow-delay="0.4s"
                                >
                                    <div className="btn-square mx-auto mb-3">
                                        <i className="fa fa-phone fa-2x text-white" />
                                    </div>
                                    <p className="mb-2">+88 01713 675820</p>
                                    <p className="mb-0">+88 01911 436087</p>
                                </div>
                                <div
                                    className="col-md-4 text-center wow fadeInUp"
                                    data-wow-delay="0.5s"
                                >
                                    <div className="btn-square mx-auto mb-3">
                                        <i className="fa fa-map-marker-alt fa-2x text-white" />
                                    </div>
                                    <p className="mb-2">Shib bari mor</p>
                                    <p className="mb-0">Khulna, 9000</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default Contact;