import React from 'react';

const Counter = () => {


    return (
        <>
            <div className="container-fluid video">
                <div className="container">
                    <h5 className='py-4 text-center text-white' style={{ lineHeight: '35px' }}>প্রডাক্টের স্টক এবং সেবার পরিমাণ</h5>
                    <div className="row g-0">
                        <div className="col-lg-6 py-lg-5 py-2 wow fadeIn" data-wow-delay="0.1s">


                            <div
                                className="h-100 d-flex align-items-center justify-content-center"
                                style={{ minHeight: 300 }}
                            >
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" fill="currentColor" className="bi bi-house-gear-fill" viewBox="0 0 16 16">
                                            <path d="M7.293 1.5a1 1 0 0 1 1.414 0L11 3.793V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v3.293l2.354 2.353a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5Z" />
                                            <path d="M11.07 9.047a1.5 1.5 0 0 0-1.742.26l-.02.021a1.5 1.5 0 0 0-.261 1.742 1.5 1.5 0 0 0 0 2.86 1.504 1.504 0 0 0-.12 1.07H3.5A1.5 1.5 0 0 1 2 13.5V9.293l6-6 4.724 4.724a1.5 1.5 0 0 0-1.654 1.03Z" />
                                            <path d="m13.158 9.608-.043-.148c-.181-.613-1.049-.613-1.23 0l-.043.148a.64.64 0 0 1-.921.382l-.136-.074c-.561-.306-1.175.308-.87.869l.075.136a.64.64 0 0 1-.382.92l-.148.045c-.613.18-.613 1.048 0 1.229l.148.043a.64.64 0 0 1 .382.921l-.074.136c-.306.561.308 1.175.869.87l.136-.075a.64.64 0 0 1 .92.382l.045.149c.18.612 1.048.612 1.229 0l.043-.15a.64.64 0 0 1 .921-.38l.136.074c.561.305 1.175-.309.87-.87l-.075-.136a.64.64 0 0 1 .382-.92l.149-.044c.612-.181.612-1.049 0-1.23l-.15-.043a.64.64 0 0 1-.38-.921l.074-.136c.305-.561-.309-1.175-.87-.87l-.136.075a.64.64 0 0 1-.92-.382ZM12.5 14a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Z" />
                                        </svg>
                                    </div>
                                    <div className="col-12 text-center">
                                        <span className="counter fs-1 text-white" data-count="3000">0</span>
                                    </div>
                                    <div className="col-12 text-center">
                                        <p className='w-75 m-auto text-white' style={{ lineHeight: '35px' }}>আমরা এই প্রডাক্ট গুলো চায়না থেকে আমদানি করে থাকি। একটি সার্কেল পরপর এবং আপনাদের ব্যপক চাহিদার ভিত্তিতে নানা ধরনের আমাদানি নিতী মেনে প্রডাক্ট গুলো আমাদানি করে থাকি। উদ্দেশ্য একটাই, আপনাদের দুশ্চিন্তা পরিহার করে পাইলস মুক্ত জীবন যাপন। বর্তমানে ৩ হাজার প্রডাক্ট আমাদের স্টকে রয়েছে। </p>
                                    </div>
                                </div>



                            </div>

                        </div>
                        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                            <div
                                className="h-100 d-flex align-items-center justify-content-center"
                                style={{ minHeight: 300 }}
                            >
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" fill="currentColor" className="bi bi-person-heart" viewBox="0 0 16 16">
                                            <path d="M9 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-9 8c0 1 1 1 1 1h10s1 0 1-1-1-4-6-4-6 3-6 4Zm13.5-8.09c1.387-1.425 4.855 1.07 0 4.277-4.854-3.207-1.387-5.702 0-4.276Z" />
                                        </svg>
                                    </div>
                                    <div className="col-12 text-center">
                                        <span className="counter fs-1 text-white" data-count="50000">0</span><span className='text-white fs-1'>+</span>
                                    </div>
                                    <div className="col-12 text-center">
                                        <p className='w-75 m-auto text-white' style={{ lineHeight: '35px' }}>আল্লাহর অশেষ রহমতে আমরা ৫০ হাজারের অধিক মানুষকে সেবা দিতে পেরেছি। উক্ত সম্মান্নিত কাস্টমারের মধ্যে অনেকে একের অধিকবার অপরেশন করেও পাইলস মুক্ত হতে পারেনি। আলহামদুলিল্লাহ, EELHOE স্প্রেটি তাদের সম্পূর্ণ সুস্থ করেছে। চিন্তামুক্ত নিরাপদ জীবন আমাদের সকলের অধিকার।</p>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>

                    <div className="row py-lg-3">
                        <div className="col-12 col-md-6 m-auto mb-5">
                            <div className="card p-3">
                                <p className='' style={{ textAlign: 'justify' }}> আমাদের EELHOE স্প্রেটির ব্যপক চাহিদা থাকায় খুব অল্পদিনের মধ্যেই শেষ হয়ে যায়। ফলে স্টকের প্রডাক্ট কিছু দিনের মধ্যে শেষ হয়ে যাবে। তাই প্রডাক্টটি শেষ হয়ে যাবার পূর্বে আপনার টি অর্ডার করুন। অন্যথায় পরবর্তী লট আশা পর্যন্ত অপেক্ষা করতে হবে। তাই অযথা নিজেকে কষ্ট না দিয়ে দুশ্চিন্তায় জীবন যাপন না করে চিন্তামুক্ত থাকুন, নিরাপদ থাকুন।</p>
                                <a className="btn btn-light bg-primary rounded-pill py-3 px-5 m-auto" href="#order">
                                    <div className="circles text-white">
                                        <div className="circle1"></div>
                                        <div className="circle2"></div>
                                        <div className="circle3"></div>
                                        অর্ডার করুন
                                    </div>
                                </a>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};

export default Counter;