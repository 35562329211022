import { createBrowserRouter } from "react-router-dom";
import Main from "../Layouts/Front/Main";
import Checkout from "../Pages/Checkout/Checkout";
import DisplayError from "../Pages/DisplayError/DisplayError";
import Terms from "../Pages/Terms";
import Privacy from "../Pages/Privacy";
import ThankYou from "../Pages/Checkout/ThankYou";


const router = createBrowserRouter([
    {
        path: '/',
        element: <Main></Main>,
        errorElement: <DisplayError />,
        children: [
            {
                path: '/',
                element: <Checkout></Checkout>
            },
            {
                path: '/piles/checkout',
                element: <Checkout></Checkout>
            },
            {
                path: '/t&c',
                element: <Terms />
            },
            {
                path: '/privacy',
                element: <Privacy />
            },
            {
                path: '/piles/thankYou',
                element: <ThankYou />
            }
        ],
    },

]);

export default router;