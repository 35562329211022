import React, { useEffect, useState } from 'react';
import ReactOwlCarousel, { Options } from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { LoaderIcon } from 'react-hot-toast';



const Testimonial = () => {

    const [loading, setLoading] = useState(true);
    const [testimonials, setTestimonials] = useState([]);


    const options = {
        margin: 30,
        responsiveClass: true,
        // nav: true,
        dots: true,
        autoplay: true,
        // navText: ["Prev", "Next"],
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 2,
            },
            700: {
                items: 3,
            },
            1000: {
                items: 5,

            }
        },
    };

    useEffect(() => {
        fetch('https://medicarebackend.m3medicare.com/api/medicare/testimonial')
            .then(res => res.json())
            .then(data => {

                setTestimonials(data)
                setLoading(false)
            })
    }, []);

    if (loading) {
        return <LoaderIcon></LoaderIcon>
    }

    return (
        <>
            <div className="container-fluid product py-3 my-3">
                <div className="container py-lg-5 py-2">
                    <div
                        className="section-title text-center mx-auto wow fadeInUp"
                        data-wow-delay="0.1s"
                        style={{ maxWidth: 500 }}
                    >
                        <p className="fs-5 fw-medium fst-italic text-primary">আমাদের হ্যাপি কাস্টমার</p>
                        <h5 className="">
                            আমাদের হ্যাপি কাস্টমার। যারা আমাদের সম্পর্কে ফিডব্যাক দিয়েছেন। তাদের কিছু অংশ।
                        </h5>
                    </div>

                    <ReactOwlCarousel className="fadeInUp owl-theme" {...options} loop>
                        {
                            testimonials?.map((testimonial, index) => {
                                return (

                                    <a href="" className="d-block product-item rounded" key={index}>
                                        <img src={`https://medicarebackend.m3medicare.com/uploads/testimonial/${testimonial?.image_1 ? testimonial?.image_1 : ''}`} alt="" />
                                        <div className="bg-white shadow-sm text-center p-1 position-relative mt-n5 mx-3">
                                            <img src={`https://medicarebackend.m3medicare.com/uploads/testimonial/${testimonial?.image_2 ? testimonial?.image_2 : ''}`} alt="" />
                                        </div>
                                    </a>
                                )
                            })
                        }
                    </ReactOwlCarousel>


                </div>
            </div>

        </>
    );
};

export default Testimonial;