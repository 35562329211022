import React from 'react';
import Video from './Video';
import Feature from './Feature';
import About from './About';
import Testimonial from './Testimonial';
import Contact from './Contact';
import Counter from './Counter';
import Faq from './Faq';

const Checkout = () => {
    return (
        <>
           <Video/>
           <Feature/>
           <About/>
           <Counter/>
           <Testimonial/>
           <Faq/>
           <Contact/>
        </>
    );
};

export default Checkout;