import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <>
            <div
                className="container-fluid bg-dark footer mt-5 py-5 wow fadeIn"
                data-wow-delay="0.1s" id='footer'
            >
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-lg-3 col-md-6  m-auto text-center">
                            <h4 className="text-primary mb-4 text-center">Our Office</h4>
                            <p className="mb-2">
                                <i className="fa fa-map-marker-alt text-primary me-3" />
                                Shib bari mor, khulna 9000
                            </p>
                            <p className="mb-2">
                                <i className="fa fa-phone-alt text-primary me-3" />
                                +88 01713 675820
                                +88 01911 436087
                            </p>
                            <p className="mb-2">
                                <i className="fa fa-envelope text-primary me-3" />
                                m3medicarebd@gmail.com
                            </p>
                            <div className="d-flex justify-content-center pt-3">
                                {/* <a className="btn btn-square btn-primary rounded-circle me-2" target='_blank' href="https://www.facebook.com/m3medicarebd">
                                    <i className="fab fa-twitter" />
                                </a> */}
                                <a className="btn btn-square btn-primary rounded-circle me-2" target='_blank' href="https://www.facebook.com/m3medicarebd">
                                    <i className="fab fa-facebook-f" />
                                </a>
                                {/* <a className="btn btn-square btn-primary rounded-circle me-2" target='_blank' href="">
                                    <i className="fab fa-youtube" />
                                </a>
                                <a className="btn btn-square btn-primary rounded-circle me-2" target='_blank' href="">
                                    <i className="fab fa-linkedin-in" />
                                </a> */}
                            </div>
                        </div>
                        {/* <div className="col-lg-3 col-md-6">
                            <h4 className="text-primary mb-4">Quick Links</h4>
                            <a className="btn btn-link" href="">
                                About Us
                            </a>
                            <a className="btn btn-link" href="">
                                Contact Us
                            </a>
                            <a className="btn btn-link" href="">
                                Our Services
                            </a>
                            <Link className="btn btn-link" to="/t&c">
                                Terms &amp; Condition
                            </Link>
                            <a className="btn btn-link" href="">
                                Support
                            </a>
                        </div> */}
                        {/*<div className="col-lg-3 col-md-6">
                            <h4 className="text-primary mb-4">Business Hours</h4>
                            <p className="mb-1">Monday - Friday</p>
                            <h6 className="text-light">09:00 am - 07:00 pm</h6>
                            <p className="mb-1">Saturday</p>
                            <h6 className="text-light">09:00 am - 12:00 pm</h6>
                            <p className="mb-1">Sunday</p>
                            <h6 className="text-light">Closed</h6>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h4 className="text-primary mb-4">Newsletter</h4>
                            <p>Dolor amet sit justo amet elitr clita ipsum elitr est.</p>
                            <div className="position-relative w-100">
                                <input
                                    className="form-control bg-transparent w-100 py-3 ps-4 pe-5"
                                    type="text"
                                    placeholder="Your email"
                                />
                                <button
                                    type="button"
                                    className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2"
                                >
                                    SignUp
                                </button>
                            </div>
                        </div> */}
                    </div>
                    <div className="d-flex justify-content-center mt-5">
                        <Link className="btn btn-link me-4" to="/t&c">
                            Terms &amp; Condition
                        </Link>
                        <Link className="btn btn-link" to="/privacy">
                            Privacy &amp; Policy
                        </Link>
                    </div>
                    <p className='text-center mt-5' style={{ fontSize: '13px' }}>আপনাদের বিশ্বাস আমাদের অর্জন।</p>
                    <p className="text-center">
                        © Copyright m3medicare. All rights reserved.
                    </p>
                </div>
            </div>

        </>
    );
};

export default Footer;