import React from 'react';
import { Link } from 'react-router-dom';
import Marquee from '../Pages/Checkout/Marquee';

const NavBar = () => {
    return (
        <>
            <div className="container-fluid bg-white sticky-top">
                <div className="container">
                    <nav className="navbar navbar-expand-lg bg-white navbar-light py-2 py-lg-0">
                        <Link to="/Checkout" className="navbar-brand" style={{}}>
                            <img className="img-fluid w-100 mt-2" src="/assets/logo/logo.png" alt="Logo" />
                        </Link>
                        <button
                            type="button"
                            className="navbar-toggler ms-auto me-0"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarCollapse"
                        >
                            <span className="navbar-toggler-icon" />
                        </button>
                        <div className="collapse navbar-collapse" id="navbarCollapse">
                            <div className="navbar-nav ms-auto">
                                <div className=' ms-auto mar-div' style={{}}>
                                    <Marquee />
                                </div>
                                
                            </div>
                            {/* <div className="border-start ps-4 d-none d-lg-block">
                                <button type="button" className="btn btn-sm p-0">
                                    <i className="fa fa-search" />
                                </button>
                            </div> */}
                        </div>
                    </nav>
                </div>
            </div>



        </>
    );
};

export default NavBar;