import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const Feature = () => {

    const [payment, setPayment] = useState(1);

    const [product, setProduct] = useState('');

    const [count, setCount] = useState(1);

    const { id, name, price, delivery, picture } = product;
   
    const [total, setTotal] = useState();
    const navigate = useNavigate();


    useEffect(() => {
        fetch('https://medicarebackend.m3medicare.com/api/medicare')
            .then(res => res.json())
            .then(data => {
                setProduct(data)
            })
    }, []);



    const handleCalculate = (isIncrease) => {
        if (isIncrease) {
            setCount(count + 1);

        } else {

            if (count > 0) {
                setCount(count - 1);

            }
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.target;

        const order = {
            product_id: id,
            price: price,
            quantity: count,
            delivery: delivery,
            name: form.name.value,
            phone: form.phone.value,
            address: form.address.value,
            payment_type: form.payment_method.value
        }


        fetch(`https://medicarebackend.m3medicare.com/api/medicare/orderSubmit`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(order)
        })
            .then(res => res.json())
            .then(data => {
                if (data) {

                    toast.success('সফলভাবে অর্ডারটি সম্পন্ন করা হয়েছে');
                    navigate('/piles/thankYou')

                }

            })
            .catch(err => {
                console.log(err);
            })

    }
    return (
        <>
            <div className="container-fluid product " id="order">
                <div className="container py-lg-5 py-2">
                    <div
                        className="section-title text-center mx-auto wow fadeInUp"
                        data-wow-delay="0.1s"
                        style={{ maxWidth: 500 }}
                    >
                        <p className="fs-5 fw-medium fst-italic mb-0 text-primary">অর্ডার করুন এখানে</p>

                    </div>

                    <section className="h-100 gradient-custom">
                        <div className="container">
                            <form action="" onSubmit={handleSubmit}>

                                <div className="row d-flex justify-content-center my-3">
                                    <div className="col-md-7">
                                        <div className="card mb-4">
                                            <div className="card-header py-3">
                                                <h5 className="mb-0 text-primary">প্রডাক্টের বিবরণ</h5>
                                            </div>
                                            <div className="card-body">
                                                {/* Single item */}
                                                <div className="row">
                                                    <div className="col-lg-7 wow fadeIn" data-wow-delay="0.1s">
                                                        <img className="img-fluid" src={`https://medicarebackend.m3medicare.com/uploads/mediProducts/${picture ? picture : ''}`} alt="" />
                                                        <p className='text-center text-primary fw-bold'>Price : {price}</p>
                                                    </div>

                                                    <div className="col-lg-5 col-md-6 mb-2 mb-lg-0">

                                                        <p className='text-center text-primary'>
                                                            <strong>{name} পাইলস স্প্রে</strong>
                                                        </p>

                                                        <p className='text-center'>30 মিঃলিঃ</p>


                                                        <div className="d-flex mb-4" style={{ maxWidth: 300 }}>
                                                            <button type='button' onClick={() => handleCalculate(false)}
                                                                className="btn btn-primary px-3 me-2" style={{ height: '38px' }}

                                                            >
                                                                <i className="fas fa-minus" />
                                                            </button>
                                                            <div className="form-outline text-center">

                                                                <input
                                                                    id="form1"
                                                                    min={0}
                                                                    name="quantity"
                                                                    value={count}
                                                                    type="number"
                                                                    className="form-control text-center"
                                                                />
                                                                <label className="form-label text-center" htmlFor="form1">
                                                                    পরিমাণ
                                                                </label>
                                                            </div>
                                                            <button type='button' onClick={() => handleCalculate(true)}
                                                                className="btn btn-primary px-3 ms-2" style={{ height: '38px' }}

                                                            >
                                                                <i className="fas fa-plus" />
                                                            </button>
                                                        </div>

                                                        <div className='mb-3 mt-3'>


                                                            <div className="d-flex justify-content-between">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="radio" name="payment_method" defaultValue={1} onChange={() => setPayment(1)} defaultChecked id="flexRadioDefault1" />
                                                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                        ক্যাশ অন ডেলিভারী
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input defaultValue={2} onChange={() => setPayment(2)} className="form-check-input" type="radio" name="payment_method" id="flexRadioDefault2" />
                                                                    <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                                        অগ্রিম পেমেন্ট
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <p className={`text-center text-danger   ${payment === 1 ? 'd-block' : 'd-none'}`}>ক্যাশ অন ডেলিভারীর ক্ষেত্রে শুধু মাত্র ডেলিভারী চার্জ অগ্রিম পরিশোধ করতে হবে। পণ্য হাতে পেয়ে বাকি টাকা পরিশোধ করতে পারবেন।</p>
                                                            <p className={`text-center text-danger   ${payment === 2 ? 'd-block' : 'd-none'}`}>অগ্রিম পেমেন্টের ক্ষেত্রে পণ্য ও ডেলিভারী চার্জ পুরোটাই অগ্রিম পরিশোধ করতে হবে।</p>
                                                        </div>

                                                        <div className="card mb-lg-4">
                                                            <div className="card-header py-3">
                                                                <h6 className="mb-0 text-primary text-center">পেমেন্ট বিস্তারিত</h6>
                                                            </div>
                                                            <div className="card-body">

                                                                <ul className="list-group list-group-flush">
                                                                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                                                                        পণ্যের দাম
                                                                        <span id='productPrice'>৳ {price * count}</span>
                                                                    </li>
                                                                    <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                                                                        ডেলিভারী ফি
                                                                        <span>{delivery}</span>
                                                                    </li>
                                                                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-3">
                                                                        <div>
                                                                            <strong>সর্বমোট</strong>

                                                                            <p className="mb-0" style={{ fontSize: '12px' }}>(ভ্যাটসহ)</p>

                                                                        </div>
                                                                        <span>
                                                                            <strong>৳ <span id='total'>{(parseInt(price) * count) + parseInt(delivery)}</span></strong>
                                                                            <input type="hidden" name="total" value={(parseInt(price) * count) + parseInt(delivery)} />
                                                                        </span>
                                                                    </li>
                                                                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-1">
                                                                        <div>
                                                                            <strong>বর্তমান পেমেন্ট</strong>

                                                                        </div>
                                                                        <span>
                                                                            {
                                                                                payment === 1 ?
                                                                                    <strong>৳ <span>{product?.delivery}</span>
                                                                                    </strong>
                                                                                    :

                                                                                    <strong>৳ <span>{parseInt((price * count)) + parseInt(delivery)}</span></strong>
                                                                            }
                                                                        </span>
                                                                    </li>
                                                                </ul>

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="card mb-4">
                                            <div className="card-header py-3">
                                                <h5 className="mb-0 text-primary">অর্ডার সম্পর্কিত তথ্য</h5>
                                            </div>
                                            <div className="card-body">

                                                <div className="mb-4">
                                                    <label htmlFor="name" className="form-label">নাম</label>
                                                    <input type="text" className="form-control" id="name" name='name' required placeholder="নাম" />
                                                </div>
                                                <div className="mb-4">
                                                    <label htmlFor="phone" className="form-label">মোবাইল নাম্বার </label>
                                                    <input type="text" className="form-control" id="phone" name='phone' required placeholder="মোবাইল নাম্বার" />
                                                </div>
                                                <div className="form-floating mb-4 form-control">
                                                    <textarea className="form-control" name='address' style={{ height: '200px' }} placeholder="আপনার ডেলিভারী ঠিকানা লিখুন" required rows="10" cols="60" id="address"></textarea>
                                                    <label htmlFor="address"></label>
                                                </div>
                                                <button type="submit" className="btn btn-primary btn-lg btn-block">
                                                    অর্ডার সম্পন্ন করুন
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </section>

                </div>
            </div>
        </>
    );
};

export default Feature;