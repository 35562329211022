import React from 'react';
import { motion } from "framer-motion";
import './Marquee.css';

const marqueeVariants = {
    animate: {
        x: [0, -1035],
        transition: {
            x: {
                repeat: Infinity,
                repeatType: "loop",
                duration: 20,
                ease: "linear",
                setInterval:1,
            },
        },
    },
};

const Marquee = () => {
    return (
        <>
            <div className="marquee">
                {/* 3. Using framer motion */}
                <motion.div
                    className="track"
                    variants={marqueeVariants}
                    animate="animate"
                >
                    <p className='pt-3 fw-bold text-danger'>
                        অর্ডার সম্পর্কীয় কোন জিজ্ঞাসা থাকলে সরাসরি আমাদের সাথে ফোন, মেইল, হোয়াটসঅ্যাপ বা ফেসবুক পেজের মাধ্যমে যোগাযোগ করতে পারেন। অর্ডার করার প্রক্রিয়া জানতে নিচের প্লে বাটন ক্লিক করুন। আপনাদের বিশ্বাস আমাদের অর্জন। ধন্যবাদ।
                    </p>
                </motion.div>
            </div>
        </>
    );
};

export default Marquee;