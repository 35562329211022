import React, { useEffect, useState } from 'react';
import './Video.css';

const About = () => {

    const [image, setImage] = useState('');
    const { about_large_1, about_large_2, about_mini_1, about_mini_2, about_mini_land } = image;
   
    useEffect(() => {
        fetch('https://medicarebackend.m3medicare.com/api/medicare/pilesimage')
            .then(res => res.json())
            .then(data => {
                setImage(data);

            })
    }, [])
    return (
        <>
            <div className="container-xxl py-lg-5 py-3">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-6">
                            <div className="row g-3">
                                <div className="col-6 text-end">
                                    <img
                                        className="img-fluid bg-white w-100 mb-3 wow fadeIn"
                                        data-wow-delay="0.1s"
                                        src={`https://medicarebackend.m3medicare.com/uploads/piles/${about_large_1 ? about_large_1 : ''}`}
                                        alt=""
                                    />
                                    <img
                                        className="img-fluid bg-white w-50 wow fadeIn"
                                        data-wow-delay="0.2s"
                                        src={`https://medicarebackend.m3medicare.com/uploads/piles/${about_mini_2 ? about_mini_2 : ''}`}
                                        alt=""
                                    />
                                </div>
                                <div className="col-6">
                                    <img
                                        className="img-fluid bg-white w-50 mb-3 wow fadeIn"
                                        data-wow-delay="0.3s"
                                        src={`https://medicarebackend.m3medicare.com/uploads/piles/${about_mini_1 ? about_mini_1 : ''}`}
                                        alt=""
                                    />
                                    <img
                                        className="img-fluid bg-white w-100 wow fadeIn"
                                        data-wow-delay="0.4s"
                                        src={`https://medicarebackend.m3medicare.com/uploads/piles/${about_large_2 ? about_large_2 : ''}`}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                            <div className="section-title">
                                <p className="fs-5 fw-medium fst-italic text-primary text-center text-lg-start">আমাদের সম্পর্কে কিছু কথা</p>
                                <h5 className="text-center text-lg-start">
                                    আলহামদুলিল্লাহ, আমরা খুবই খুশি, প্রডাক্টটি সফলভাবে আপনাদের হাতে তুলে দিতে পেরে।
                                </h5>
                            </div>
                            <div className="row g-3 mb-4">
                                <div className="col-sm-4">
                                    <img
                                        className="img-fluid bg-white w-100"
                                        src={`https://medicarebackend.m3medicare.com/uploads/piles/${about_mini_land ? about_mini_land : ''}`}
                                        alt=""
                                    />
                                </div>
                                <div className="col-sm-8">
                                    <p>আপনি দিনে যতবার  খুশি এটি ব্যবহার করতে পারবেন।</p>
                                    <p className="mb-0" style={{ textAlign: 'justify' }}>
                                        সাধারণত ০৩-০৪ টি প্রডাক্টই পাইলস সমূলে নির্মূল করবে। আমরা আশা করি 0১ মাসের মধ্যে আপনি সন্তোষজনক রেজাল্ট পাবেন। পাইলসের ধরন ভেদে ০১-০৩ মাসের মধ্যে সম্পূর্ণ নিরাময় হবে ইনশাআল্লাহ।
                                    </p>
                                </div>
                            </div>
                            <div className="border-top mb-4" />
                            <div className="row g-3">
                                <div className="col-sm-8">
                                    <h5>খাবারের বিধি নিষেধ</h5>
                                    <p className="mb-0 mt-3" style={{ textAlign: 'justify' }}>
                                        যেহেুতু পাইলস আমাদের মলদ্বারের বাহিরে বা ভিতরে হয়ে থাকে, সেহেতু খাবারের সাথে এটির সম্পর্ক অনেক বেশি। যদিও আমরা খাবারের বিষয়ে কোন বিধি নিষেধ প্রদান করি না। তবুও বিশেষজ্ঞ ডাক্তাদের মতে পাইলস নির্মূলের জন্য মাংস জাতীয় খাবার এড়িয়ে, সবুজ শাক বা সবজি জাতীয় খাবার গ্রহণ  করাই ভালো। বিশেষ করে পাইলস পুরোপুরি নির্মূল না হওয়া পর্যন্ত এই খাবার গুলো পরিহার করুন।
                                    </p>
                                </div>
                                <div className="col-sm-4">
                                    <img
                                        className="img-fluid bg-white w-100"
                                        src="img/about-6.jpg"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default About;