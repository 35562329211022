import React from 'react';
import { Link } from 'react-router-dom';
import './Video.css';

const Video = () => {
    return (
        <>
            <div className="container-fluid video">
                <div className="container">
                    <div className="row g-0">
                        <div className="col-lg-6 py-5 wow fadeIn" data-wow-delay="0.1s">
                            <div className="py-lg-5">
                                <h2 className="text-decoration-underline main-heading mb-4 fst-italic" style={{ lineHeight: '40px' }}>
                                    আপনি  জানেন কি? মলত্যাগের সময় রক্ত পড়া, এবং বারবার পাইলসের অপারেশন থেকে কোলন ক্যান্সার হতে পারে!!!
                                </h2>
                                <h5 className="fw-normal text-decoration-underline fst-italic text-white mb-5 sub-heading">
                                    অপারেশন ছাড়া পাইলস নিরাময় করুন, বাকী জীবন একটি টাকা ও খরচ করতে হবে না ।
                                </h5>
                                <div className="row g-4 mb-lg-5 mb-3">
                                    <div className="col-sm-6">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0 btn-lg-square bg-dark text-primary rounded-circle me-3 tick">
                                                <i className="fa fa-check" />
                                            </div>
                                            <span className="text-white list" >কোন পার্শ্ব প্রতিক্রিয়া নেই। তাই এটি ব্যাবহারে আপনি থাকবেন নিরাপদ।</span>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0 btn-lg-square bg-dark text-primary rounded-circle me-3 tick">
                                                <i className="fa fa-check" />
                                            </div>
                                            <span className="text-white list" >  ০১ থেকে ০৩ মাসের মধ্যে পাইলস সম্পূর্ণ নিরাময় করে।</span>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0 btn-lg-square bg-dark text-primary rounded-circle me-3 tick">
                                                <i className="fa fa-check" />
                                            </div>
                                            <span className="text-white list" >নিয়মিত ব্যবহারে পুনরায় পাইলস হবার সম্ভাবনা নেই। </span>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0 btn-lg-square bg-dark text-primary rounded-circle me-3 tick">
                                                <i className="fa fa-check" />
                                            </div>
                                            <span className="text-white list" >অপারেশনের মত ঝুকি থেকে মুক্ত থাকুন। নিরাপদ জীবন যাপন করুন।</span>
                                        </div>
                                    </div>
                                </div>
                                <a className="btn btn-light rounded-pill py-3 px-5 order-btn " href="#order">
                                    <div className="circles">
                                        <div className="circle1"></div>
                                        <div className="circle2"></div>
                                        <div className="circle3"></div>
                                        অর্ডার করুন
                                    </div>
                                </a>
                            </div>
                        </div>


                        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                            <div
                                className="h-100 d-flex flex-column align-items-end justify-content-center">
                                 <iframe
                                    className="embed-responsive-item"
                                    src="https://www.youtube.com/embed/aFqbcDOBj84?si=NdMHyJJ_Al0RO552&amp;start=1"
                                    id="video"
                                    allowFullScreen={true}
                                    allowscriptaccess="always"
                                    allow="autoplay"
                                />

                                <h5 className='pt-lg-4 pt-3 pb-3 text-white fw-bold'>অর্ডার সম্পর্কে বিস্তারিত জানতে ভিডিওটি দেখুন</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div
                className="modal modal-video fade"
                id="videoModal"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content rounded-0">
                        <div className="modal-header">
                            <h3 className="modal-title" id="exampleModalLabel">
                                Youtube Video
                            </h3>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body">
                            {/* 16:9 aspect ratio */}
                            <div className="ratio ratio-16x9">
                                // eslint-disable-next-line jsx-a11y/iframe-has-title
                                <iframe
                                    className="embed-responsive-item"
                                    src="https://www.youtube.com/embed/aFqbcDOBj84?si=NdMHyJJ_Al0RO552&amp;start=1"
                                    id="video"
                                    allowFullScreen=""
                                    allowscriptaccess="always"
                                    allow="autoplay"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
};

export default Video;