import React, { useEffect, useState } from 'react';

const Terms = () => {
    const [terms, setTerms] = useState('')
    useEffect(() => {
        fetch('https://medicarebackend.m3medicare.com/api/medicare/terms')
            .then(res => res.json())
            .then(data => {
                console.log(data)
                setTerms(data)
            })
    }, [])
    return (
        <>
            <div className="container" dangerouslySetInnerHTML={{ __html: terms.terms }}>

            </div>

        </>
    );
};

export default Terms;