import React, { useEffect, useState } from 'react';

const Privacy = () => {

    const [privacy, setPrivacy] = useState('')
    useEffect(() => {
        fetch('https://medicarebackend.m3medicare.com/api/medicare/privacy')
            .then(res => res.json())
            .then(data => {
                
                setPrivacy(data)
            })
    }, []);

    return (
        <div className='container' dangerouslySetInnerHTML={{ __html: privacy.privacy }}>

        </div>
    );
};

export default Privacy;