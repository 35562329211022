import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../../partials/Footer';
import NavBar from '../../partials/NavBar';

const Main = () => {
    return (
        <div>

            <NavBar />
            <Outlet />

            <Footer />

        </div>
    );
};

export default Main;